<template>
<!--  <HelloWorld msg="Meta Support"/>-->
  <RouterView />
</template>

<script>
export default {
  name: 'App',
  components: {

  }
}
</script>